.card {
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  display: flex;
  text-align: center;

}

.camera-icon {
    margin-bottom: 5px;
}

.black {
  color: black
}