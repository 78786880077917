#pricing-plan {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


#box {
    @media(max-width:1078px){
        display: flex;
        flex-direction: column; 
        align-items: center;
        p{
            width: 80%;
        }
    }
       
      @media(max-width:990px){
            display: flex;
            flex-direction: column; 
            align-items: center;
           
    
            p{
                width: 100%;
                margin-top:-10px;
            }
        }
    
    }
    