.floating-button {
  position:fixed;
	display: flex;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0A0A0A;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
	z-index: 999;
	align-items: center;
	justify-content: center;

	.icon {
		color: #FFF;
		transform: translateX(5px);
		align-items: center;
	}

  &:hover {
    .icon {
      transform: translateX(5px);
    }
  }
}

.whatsapp {
	background-color: #045e54;
	color: white;
}